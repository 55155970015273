<template>
  <div>
      <el-container style="min-height: 100%">
          <el-header>
              <!--  头部  -->
              <div class="heard">
                  <div class="pointer" @click="upload">
                      <div class="image">
                          <img  src="../../assets/logo.gif" alt="" class="image1">
                      </div>
                  </div>
                  <div style="flex: 1">
                      <el-menu :default-active="'1'" class="el-menu-demo" mode="horizontal"  active-text-color="#310052" router>
                          <el-menu-item class="el_menu_item1" index="/front/home">Home</el-menu-item>
                          <el-menu-item class="el_menu_item2" index="/front/talisman">Talisman Fu</el-menu-item>
                          <el-menu-item class="el_menu_item3" index="/front/houresFengshui">Houres Fengshui</el-menu-item>
                          <el-menu-item class="el_menu_item4" index="/front/maoshanShangqing">Maoshan Shangqing</el-menu-item>
                          <el-menu-item class="el_menu_item1" index="/front/media">Media</el-menu-item>
                          <!--<el-menu-item class="el_menu_item" index="/front/claims">Test</el-menu-item>-->
                      </el-menu>
                  </div>
                  <div style="width: 200px">
                      <div v-if="!user.username" style="text-align: right; padding-right: 30px">
                          <el-button @click="$router.push('/login')">Login</el-button>
                          <el-button @click="$router.push('/register')">Register</el-button>
                      </div>
                      <div v-else>
                          <el-dropdown style="width: 150px; cursor:pointer; text-align: right">
                              <div style="display: inline-block">
                                  <img :src="user.avatarUrl" alt=""
                                       style="width: 30px; height: 30px;border-radius: 50%;position: relative;top: 10px;right: 5px">
                                  <span>{{user.nickname}}</span><i class="el-icon-arrow-down" style="margin-left: 5px"></i>
                              </div>
                              <el-dropdown-menu slot="dropdown" style="width: 100px; text-align: center">
                                  <el-dropdown-item style="font-size: 14px;padding: 5px 0">
                                      <router-link to="/front/person" style="text-decoration:none">个人信息</router-link>
                                  </el-dropdown-item>
                                  <el-dropdown-item style="font-size: 14px;padding: 5px 0">
                                      <span style="text-decoration: none" @click="logout">退出</span>
                                  </el-dropdown-item>
                              </el-dropdown-menu>
                          </el-dropdown>
                      </div>
                  </div>
              </div>
          </el-header>

          <!--     主体     -->
          <el-main class="main">
              <!--<div style="width: 100%; height: 100vh;">
                    <router-view />
              </div>-->
              <router-view />
          </el-main>

          <!--     底部     
          <el-footer style="background-color: #000; width: 100%; height: 130px">
              
          </el-footer>-->
      </el-container>


    <div>

    </div>
  </div>
</template>

<script>
import router, {resetRouter, setRoutes} from "@/router";

export default {
  name: "Front",
  data(){
    return{
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
    }
  },
  created() {
  },
  methods: {
    logout(){
      this.$store.commit("logout")
      this.$message.success("退出成功！")
      location.reload()
    },
    upload(){
      this.$router.push("/front/home")
    },
  }
}
</script>

<style>
.heard {
    display: flex;
    height: 61px; 
    line-height: 60px; 
    border-bottom: 1px solid rgba(238,238,238,0);
}
.pointer{
    cursor: pointer;
    width: 200px; 
    display: flex; 
    padding-left: 30px;
}
.image {
    width: 40px; 
    margin-top: 7px;
}
.image1 {
    width:80px; 
    margin-top: 5px; 
    margin-left: 100px;
}
.main{
    background-size: 100%;
    background-attachment: fixed;
    padding: 0;
}
.el-menu-demo {
    overflow-x: auto;
    width: 1080px;
}
.el_menu_item1 {
    letter-spacing: 3px;
    font-size: 16px;
    width: 100px;
    text-align: center;
}
.el_menu_item2 {
    letter-spacing: 3px;
    font-size: 16px;
    width: 150px;
    text-align: center;
}
.el_menu_item3 {
    letter-spacing: 3px;
    font-size: 16px;
    width: 210px;
    text-align: center;
}
.el_menu_item4 {
    letter-spacing: 3px;
    font-size: 16px;
    width: 240px;
}
</style>